





































































































import './ProjectTasks.scss';
import './ProjectTasksAdaptive.scss';
import { Component, Watch, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { IProjectTasksState, ITask, ITaskGroup } from '@store/modules/project-tasks/interfaces/Interfaces';
import {
    IAddTaskPostData,
    IReturnTaskPostData,
    IAcceptTaskPostData,
    ITakeTaskPostData, ICompleteTaskPostData, IChangeTaskStatePostData
} from '@store/modules/project-tasks/interfaces/ApiRequests';
import { ProjectTasksActions, ProjectTasksMutations } from '@store/modules/project-tasks/Types';
import { IUserState } from "@store/modules/user/Interfaces";
import {userNamespace} from "@store/modules/user";

const NSUser = namespace('storeUser')
const NSProject = namespace('storeProject');
const NSProjectTasks = namespace('storeProjectTasks');
const projectDocumentsNamespace = namespace('storeProjectDocuments');

@Component({
    name: 'ProjectTasks',

    components: {
        ProjectTasksHeader: () => import('./components/ProjectTasksHeader.vue'),
        BaseGroup: () => import('@components/BaseGroup/BaseGroup.vue'),
        BasePreloader: () => import('@components/BasePreloader'),
        PopupConfirmation: () => import('@/components/popups/PopupConfirmation.vue'),
        TaskRow: () => import('./components/TaskRow.vue'),
        PopupAddTask: () => import('@components/popups/PopupAddTask.vue'),
        PopupTaskDetails: () => import('@components/popups/PopupTaskDetails.vue'),
    },
})
export default class ProjectTasks extends Vue {
    $refs!: {
        projectTasksHead: HTMLFormElement,
        popupTaskDetails: HTMLFormElement,
    }

    @NSProject.Getter('projectId') projectId!: number;
    @NSProject.Getter('projectData') projectData!: any;
    @NSUser.Getter('userData') userData!: IUserState;
    @NSUser.Getter('userRightOnlyGSN') userRightOnlyGSN!: boolean;
    @NSUser.Getter('isAdminOnCurrentProject') isAdminOnCurrentProject!: boolean;
    @NSUser.State((state: IUserState) => state.userInfo.id) userUuid!: string;
    @NSProjectTasks.Getter('taskGroups') taskGroupsStore!: ITaskGroup[];
    @NSProjectTasks.Getter('filters') filters!: any[];
    @NSProjectTasks.Getter('filtersApply') filtersApply!: boolean;
    @NSProjectTasks.State((state: IProjectTasksState) => state.projectTasksLoaded) projectTasksLoaded!: boolean;
    @NSProjectTasks.State((state: IProjectTasksState) => state.groupsFilter) groupsFilter!: string;
    @NSProjectTasks.Mutation(ProjectTasksMutations.M_CHANGE_GROUPS_FILTER)
    changeGroupsFilterMutation!: (filterValue: 'all' | 'new' | 'assign' | 'execute' | 'done') => void;

    @NSProjectTasks.Action(ProjectTasksActions.A_CREATE_NEW_TASK)
    sendAddTaskFormAction!: ({ postData: IAddTaskPostData, type: string }) => Promise<any>

    @NSProjectTasks.Action(ProjectTasksActions.A_RETURN_TASK)
    sendReturnTaskFormAction!: (payload: { params: IReturnTaskPostData }) => Promise<void>

    @NSProjectTasks.Action(ProjectTasksActions.A_ACCEPT_TASK)
    sendAcceptTaskFormAction!: (payload: { params: IReturnTaskPostData }) => Promise<void>

    @NSProjectTasks.Action(ProjectTasksActions.A_TAKE_TASK_TO_WORK)
    sendTakeTaskFormAction!: (payload: { params: ITakeTaskPostData }) => Promise<void>

    @NSProjectTasks.Action(ProjectTasksActions.A_COMPLETE_TASK)
    sendCompleteTaskFormAction!: (payload: { params: ICompleteTaskPostData }) => Promise<void>;

    @NSProjectTasks.Action(ProjectTasksActions.A_DELETE_TASK)
    deleteTask!: (payload: { appId: string | number, ids: string[], type: string }) => Promise<void>

    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED)
    setProjectTasksLoaded!: (loadedState: boolean) => void;

    @NSProjectTasks.Action(ProjectTasksActions.A_GET_PROJECT_TASKS)
    getProjectTasks!: (projectId: string | number) => Promise<void>;

    @NSProjectTasks.Mutation(ProjectTasksMutations.M_SET_PROJECT_TASKS_LOADED)
    setProjectTasksStore!: (loadedState: boolean) => void;

    confirmSliderToggler: boolean = false;
    popupAddTaskToggler: boolean = false;
    popupTaskDetailsToggler: boolean = false;
    confirmableAction: 'return' | 'accept' | '' = '';
    actionOnTask = {} as ITask;
    selectedTasks: ITask[] = [];
    deletedTasks = [] as string[];
    deleteTaskConfirmBtnDisabled: boolean = false;
    timeForReloadSubtasksList: number = 500;
    showInformationPopupDuration: number = 2500;
    informationPopupShow: boolean = false;
    informationPopupText: string = '';
    popupSuccessDeleteOpen: boolean = false;
    confirmRemoveSubtaskPopupShow: boolean = false;
    deletionConfirmationTextSubtask: string = '';
    selectedSubtask = {} as ITask;
    accessDeniedPopupShow: boolean = false;
    accessDeniedText: string = '';
    changeGroups: boolean = false;
    taskFilters: any[] = [];
    disabledAllCheckboxes: boolean = false;

    mounted() {
        this.changeGroupsFilterMutation('all');
    }

    get taskGroups() {
        const unreadTasks = {
            name: 'unread',
            tasks: this.unreadTasks,
            title: 'Непрочитанные',
        }
        return [unreadTasks].concat(this.taskGroupsStore);
    }

    get unreadTasks() {
        if (this.projectData) {
            const unreadTasksIds = this.projectData.newTasks;
            let unreadTasks: any[] = [];
            this.taskGroupsStore.forEach((group) => {
                group.tasks.forEach((task) => {
                    if (unreadTasksIds.includes(task.uuid)) {
                        unreadTasks.push(JSON.parse(JSON.stringify(task)));
                    }
                })
            })
            return unreadTasks;
        }
        return [];
    }

    get confirmationTitle() {
        switch (this.confirmableAction) {
            case 'return':
                return 'Вы уверены, что хотите вернуть задачу на доработку?';
            case 'accept':
                return 'Вы уверены, что хотите принять задачу?';
        }
    }

    get activeTaskGroups() {
        return JSON.parse(JSON.stringify(
            this.groupsFilter === 'all'
                ? this.taskGroups
                : [this.taskGroups.find(group => group.name === this.groupsFilter)]
        ))
    }

    get filteredTasksGroupsToDisplay() {
        let filteredTaskGroups = JSON.parse(JSON.stringify(this.activeTaskGroups));
        if (!this.filtersApply) return filteredTaskGroups;
        filteredTaskGroups.forEach(tasksGroup => {
            this.filters.forEach((filterObject) => {
                if (tasksGroup) {
                    if (filterObject.type === 'checkboxes' || filterObject.type === 'select') {
                        switch (filterObject.key) {
                            case ('author.fullName'):
                                tasksGroup.tasks = tasksGroup?.tasks.filter(
                                    task => filterObject.selected.includes(task.author.fullName)
                                );
                                break;
                            case ('executor.fullName'):
                                tasksGroup.tasks = tasksGroup?.tasks.filter(task =>
                                    filterObject.selected.includes(task.executor?.fullName)
                                );
                                break;
                            case ('state.name'):
                                tasksGroup.tasks = tasksGroup?.tasks.filter(task =>
                                    filterObject.selected.includes(task.state.name)
                                );
                                break;
                        }
                    }
                    if (
                        filterObject.type === 'dateRange' &&
                        (filterObject.datesRange.dateFrom || filterObject.datesRange.dateTo)
                    ) {
                        tasksGroup.tasks = tasksGroup?.tasks.filter(
                            task => filterObject.datesRange.dateFrom
                                ? Date.parse(task[filterObject.key]) >= Date.parse(filterObject.datesRange.dateFrom)
                                : true
                        );
                        tasksGroup.tasks = tasksGroup?.tasks.filter(
                            task => filterObject.datesRange.dateTo
                                ? Date.parse(task[filterObject.key]) <= Date.parse(filterObject.datesRange.dateTo)
                                : true
                        );
                    }
                }
            })
        })
        return filteredTaskGroups;
    }

    get disabledSelectionTasks() {
        const allTasks: string[] = [];
        if (this.isAdminOnCurrentProject) return new Set();
        this.taskGroups.forEach(group => {
            group.tasks.forEach((task) => {
                if (task.author.uuid !== this.userData.userInfo.id) {
                    allTasks.push(task.uuid);
                }
            });
        })
        return new Set(allTasks);
    }

    get newTaskDetailsSliderInfo () {
        const selectedTaskId = this.actionOnTask.uuid;
        let index = 0;
        let updatedTaskInfo = {} as ITask;
        this.filteredTasksGroupsToDisplay.forEach((item, itemIndex) => {
            if (item.tasks.filter(task => task.uuid === selectedTaskId).length) {
                index = itemIndex;
            }
        });
        if (this.filteredTasksGroupsToDisplay[index] && this.filteredTasksGroupsToDisplay[index].tasks) {
            updatedTaskInfo = this.filteredTasksGroupsToDisplay[index].tasks.filter((item) => item.uuid === selectedTaskId)[0];
        }

        return updatedTaskInfo;
    }

    get disabledEscKey() {
        return this.informationPopupShow || this.confirmRemoveSubtaskPopupShow || this.accessDeniedPopupShow;
    }

    get() {
        return Object.values(this.selectedTasks);
    }

    @Watch('$route.params.projectId')
    watchProjectId() {
        this.setProjectTasksLoaded(false);
    }

    @Watch('actionOnTask')
    watchActionOnTask() {
        if (this.actionOnTask.files && this.actionOnTask.files.length) {
            const newFiles: any[] = [];
            this.actionOnTask.files.forEach((item) => {
                if (item.size && item.title) {
                    newFiles.push(item);
                }
            });
            this.actionOnTask.files = newFiles;
        }
    }

    @Watch('filters')
    watchFilters() {
        if (this.filtersApply) {
            this.taskFilters = this.filters;
        }
    }

    sendCommentSuccessfull(successText) {
        this.reloadStateAndShowSuccessPopup(successText);
    }

    changeGroupsFilter() {
        this.changeGroups = !this.changeGroups;
    }

    confirmDeleteTask(): void {
        this.deleteTaskConfirmBtnDisabled = true;
        this.disabledAllCheckboxes = true;
        this.deleteTask({
            appId: this.projectId,
            ids: this.selectedTasks.map(task => task.uuid),
            type: 'task',
        }).then(() => {
            this.popupSuccessDeleteOpen = true;
            this.deleteTaskConfirmBtnDisabled = false;
            this.deletedTasks.push(...this.selectedTasks.map(task => task.uuid));
            setTimeout(() => {
                this.popupSuccessDeleteOpen = false;
                this.disabledAllCheckboxes = false;
                this.deleteTaskConfirmBtnDisabled = false;
                this.resetSelectionTasks();
            }, this.showInformationPopupDuration);
        }).catch(() => {
            this.disabledAllCheckboxes = false;
            this.deleteTaskConfirmBtnDisabled = false;
        });
    }

    switchPopupToggler(togglerKey: string, toggleState: boolean) {
        this[togglerKey] = toggleState;
    }

    takeActionOnTask(task: ITask, actionSliderToggler: string) {
        this.actionOnTask = { ...task };
        this.switchPopupToggler(actionSliderToggler, true)
    }

    takeConfirmableActionOnTask(task: ITask, action: 'return' | 'accept') {
        this.actionOnTask = task;
        this.confirmableAction = action;
        this.switchPopupToggler('confirmSliderToggler', true);
    }

    confirmActionOnTask(data: { taskFormData: IChangeTaskStatePostData, confirmableAction: string }) {
        switch (data.confirmableAction) {
            case 'return':
                this.sendReturnTaskFormAction({ params: data.taskFormData })
                    .then(() => {
                        setTimeout(() => {
                            this.reloadStateAndShowSuccessPopup('Задача отправлена на доработку');
                        }, 1000);
                    });
                break;
            case 'accept':
                this.sendAcceptTaskFormAction({ params: data.taskFormData })
                    .then(() => {
                        this.reloadStateAndShowSuccessPopup('Задача принята');
                    });
                break;
        }
        this.switchPopupToggler('popupTaskDetailsToggler', false);
    }

    formChangeTaskStateFormData(): IReturnTaskPostData | IAcceptTaskPostData {
        return {
            appId: this.projectId,
            id: this.actionOnTask.uuid,
        };
    }

    async sendAddTaskForm(taskFormData: IAddTaskPostData, isSubtask: boolean = false) {
        await this.sendAddTaskFormAction({
            postData: taskFormData, type: isSubtask ? '' : 'task'
        }).then(() => {
            if (isSubtask) {
                setTimeout(() => {
                    this.updateTaskDetailsSlider();
                }, this.timeForReloadSubtasksList + 100);
                this.showInformationPopup('Подзадача добавлена');
            }
            else {
                this.reloadStateAndShowSuccessPopup('Задача успешно создана');
            }
            this.switchPopupToggler('popupAddTaskToggler', false);
        });
    }

    updateTaskDetailsSlider() {
        setTimeout(() => {
            this.getProjectTasks(this.projectId).then(() => {
                this.actionOnTask = this.newTaskDetailsSliderInfo;
            });
        }, this.timeForReloadSubtasksList + 100);
    }

    changeTask() {
        this.showInformationPopup('Задача успешно отредактирована');
        this.resetSelectionTasks();
    }

    showInformationPopup(text) {
        this.informationPopupShow = true;
        this.informationPopupText = text;

        setTimeout(() => {
            this.informationPopupShow = false;
            this.informationPopupText = '';
        }, this.showInformationPopupDuration);
    }

    addTask(taskFormData: IAddTaskPostData, isSubtask: boolean = false) {
        this.sendAddTaskForm(taskFormData, isSubtask);
    }

    deleteSubtaskOpenConfirmPopup(subtask) {
        this.deletionConfirmationTextSubtask = `Вы уверены, что хотите удалить подзадачу "${subtask.title}"?`;
        this.confirmRemoveSubtaskPopupShow = true;
    }

    onConfirmDeleteSubtask() {
        this.confirmRemoveSubtaskPopupShow = false;
        this.deleteSubtasks();
    }

    checkBeforeDelete(subtask) {
        let canBeDelete: boolean = true;
        this.selectedSubtask = subtask;

        if (subtask.author.uuid !== this.userUuid) {
            canBeDelete = false;
            this.accessDeniedText = `Вы не можете удалить подзадачу "${subtask.title}", так как не являетесь её автором`;
        }

        if (canBeDelete) {
            this.deleteSubtaskOpenConfirmPopup(subtask);
        } else {
            this.switchPopupToggler('accessDeniedPopupShow', true);
        }
    }

    deleteSubtasks() {
        let subtask = this.selectedSubtask;

        this.deleteTask({
            appId: this.projectId,
            ids: [subtask.uuid],
            type: '',
        }).then(() => {
            this.updateTaskDetailsSlider();
            this.showInformationPopup(`Подзадача "${subtask.title}" успешно удалена`);
        })
    }

    reloadStoreTasks() {
        this.getProjectTasks(this.projectId).then(() => {
            this.setProjectTasksStore(true);
        });
    }

    reloadStateAndShowSuccessPopup(popupText) {
        setTimeout(() => {
            this.reloadStoreTasks();
        }, this.timeForReloadSubtasksList + 200);
        this.showInformationPopup(popupText);
    }

    async takeTask(taskFormData: ITakeTaskPostData) {
        await this.sendTakeTaskFormAction(
            { params: taskFormData }
        ).then(() => {
            this.reloadStateAndShowSuccessPopup('Задача принята в работу');
        });
    }

    async completeTask(taskFormData: IChangeTaskStatePostData) {
        await this.sendCompleteTaskFormAction({
            params: taskFormData,
        }).then(() => {
            this.reloadStateAndShowSuccessPopup('Задача отправлена');
        });
    }

    resetSelectionTasks() {
        this.selectedTasks = [];
    }

    onSelectTask(e) {
        if (this.$refs.projectTasksHead) {
            this.changeSelectedTask(e);
        }
    }

    changeSelectedTask(e) {
        const index = this.selectedTasks.indexOf(e);
        if (index === -1) {
            this.selectedTasks.push(e);
        } else {
            this.selectedTasks.splice(index, 1);
        }
    }
}
